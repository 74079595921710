<template>
  <div class="gg-container">
    <div class="search-container">
      <div class="search-container-fn-input" style="margin-left: auto">
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="success"
            icon="el-icon-plus"
            style="padding: 7px 8px;margin-left: 5px;"
            @click="editReply('add')"
          >
            <span style="font-size: 12px">新增</span>
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleDelete(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
      </div>
    </div>
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="editArticle('view', scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'org_name'">
              {{ scope.row.third_status ? "是" : "否" }}
            </a>
            <a v-if="column.column_prop == 'admin'">
              {{ scope.row.admin.name }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'group_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.group_name)
                "
                v-model="searchParams.group_id"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'trigger' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.trigger)"
                v-model="searchParams.trigger"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'from_type' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.from_type)
                "
                v-model="searchParams.from_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in from_type_List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'client' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.client)"
                v-model="searchParams.client_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in clientList"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!--添加弹出框-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleCancel"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
        >新增用法标签</span
      >
      <el-input
        v-model="content"
        size="mini"
        autocomplete="off"
        clearable
        placeholder="请输入"
        style="margin-bottom: 20px"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleCancel"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleConfirm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import Pagination from "@/components/Pagination";
import {
  getTakeMethod,
  postTakeMethodAddMethod,
  postTakeMethodUpdateMethod,
  postTakeMethodDeleteMethod,
  postTakeMethodMethodStatus,
} from "@/api/basic";
import { mapState } from "vuex";
export default {
  name: "UsageManager",
  components: {
    Pagination,
    editTableHead,
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  data() {
    return {
      table_type:'usageManager',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "group_name",
          isSearch: false,
        },
        {
          name: "from_type",
          isSearch: false,
        },
        {
          name: "trigger",
          isSearch: false,
        },
        {
          name: "client",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {},
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      options: [],
      dialogVisible: false,
      tableData: [],
      content: "", //输入框的内容
      choice: "", // add 添加 edit 编辑
      id: "", // 当前编辑的id
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "usageManager",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.usageManager;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    test() {},
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      getTakeMethod(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    async _postTakeMethodAddMethod() {
      this.listLoading = true;
      var data = {
        content: this.content,
      };
      try {
        var res = await postTakeMethodAddMethod(data);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "添加成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.listLoading = false;
        this.getList();
      } catch (e) {
        this.listLoading = false;
      }
    },
    async _postTakeMethodUpdateMethod() {
      this.listLoading = true;
      var data = {
        content: this.content,
        id: this.id,
      };
      try {
        var res = await postTakeMethodUpdateMethod(data);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.listLoading = false;
        this.getList();
      } catch (e) {
        this.listLoading = false;
      }
    },
    changeExamine(row) {
      //选择状态 (启用/禁用)
      console.log(row.status);
      this.listLoading = true;
      var data = {
        id: row.id,
        status: row.status,
      };
      postTakeMethodMethodStatus(data)
        .then((response) => {
          if (response.code === 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: response.msg,
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "修改失败!",
          });
          this.listLoading = false;
        });
    },
    handleAdd(type) {
      this.choice = type;
      this.dialogVisible = true;
    },
    async handleEdit(type, row) {
      this.choice = type;
      this.dialogVisible = true;
      this.content = row.content;
      this.id = row.id;
    },
    handleDelete(row) {
      console.log(row);
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          var data = {
            id: row.id,
          };
          postTakeMethodDeleteMethod(data)
            .then((res) => {
              if (res.code === 200) {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
              this.listLoading = false;
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "网络错误",
              });
              this.listLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    async handleConfirm() {
      if (this.content.length === 0) {
        this.$message({
          type: "warning",
          message: "请输入内容",
        });
      } else {
        if (this.choice == "add") {
          await this._postTakeMethodAddMethod();
        } else {
          await this._postTakeMethodUpdateMethod();
        }
        this.dialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*弹出框表单配置*/
/deep/ .el-form-item__label {
  padding-bottom: 0;
}

/deep/ .el-form-item__content {
  width: var(--widthToTwo);
}

.from-item-second {
  margin-left: 0;
}

/deep/ .from-item-third {
  .el-form-item__content {
    width: var(--widthToOne);
  }

  .el-select {
    width: var(--widthToOne) !important;
  }
}

/deep/ .el-cascader {
  width: var(--widthToTwo);
}

/deep/ .el-select {
  width: var(--widthToTwo) !important;
}

/deep/ .el-date-editor {
  width: var(--widthToTwo) !important;
}

/*select 宽度配置*/
/deep/ .el-select {
  width: 85px;
}
</style>
